// import banner_img from "./images/banner-img.webp";
import banner_img from "./images/bannerimg.png";

import CarousalFirst from "./images/carousal_1.png";
import CarousalSecond from "./images/carousal_2.png";
import CarousalThird from "./images/carousal_3.png";
import CarousalFourth from "./images/carousal_4.png";
import CarousalFifth from "./images/carousal_5.png";
import Segment1 from "./images/segment_1.png";
import Segment2 from "./images/segment_2.png";
import Segment3 from "./images/loyalty.png";
import Segment4 from "./images/segment_4.png";
import seg from "./image/whatspp.png";
import ShopType1 from "./images/shop_type_1.png";
import ShopType2 from "./images/shop_type_2.png";
import ShopType3 from "./images/shop_type_3.png";
import ShopType4 from "./images/shop_type_4.png";
import ShopType5 from "./images/shop_type_5.png";
import ShopType6 from "./images/shop_type_6.png";
import GourmentBiriyani from "./images/customer_Img_Gourment_Biriyani.jpeg";
import JayanthiAgency from "./images/customer_Img_Jayanthi_Agency.jpeg";
import SpectrumNetwork from "./images/customer_Img_Spectrum_Network.jpeg";
import Footer from "./footer";
import img1 from "./image/whatspp.png";
import img2 from "./image/play.png";
// import img3 from "./image/Landing_Page_Illustration.png";
import img3 from "./image/landingpageimg.png";
import img4 from "./image/fb.png";
import img5 from "./image/insta.png";
import img6 from "./image/youtube.png";
import img7 from "./image/LinkedIN.png";
import img8 from "./image/interiorimg.png";
import img9 from "./image/retailimg.png";
import img10 from "./image/fashionimg.png";
import img11 from "./image/architectimg.png";
import img12 from "./image/Billing-Illsutration.png";
import img13 from "./image/Scan_and_Order.png";
import img14 from "./image/Satisfaction_illustration.png";
import img15 from "./image/Inventory_Illustration.png";
import img16 from "./image/project1.png";
import img17 from "./image/project3.png";
import img18 from "./image/project2.png";
import img19 from "./image/project4.png";
import img20 from "./image/agency.jpeg";
import img21 from "./image/biriyani.jpeg";
import img22 from "./image/network.jpg";
import img23 from "./image/Website_Illustration.png";
import img24 from "./image/leftarrow.png";
import img25 from "./image/rightarrow.png";
import img26 from "./image/upquote.png";
import img27 from "./image/downquote.png";
import img28 from "./image/ProjectSteps_001.png";
import img29 from "./image/posimg.png";
import img30 from "./image/marketing.png";
import img31 from "./image/appointmentimg.png";
import img32 from "./image/website.png";
import img33 from "./image/onlinestoreimg.png";
import img34 from "./image/CXO-Outlook_Trade-marked.png";
import img35 from "./image/yourstory.png";
import img36 from "./image/Swiftnift.png";
import img37 from "./image/StartupTalky_logo-3.png";
import img38 from "./image/petimg.png";
import img39 from "./image/restaurantimg.png";
import img40 from "./image/fnnlogo.png";

// import img29 from "./image/step2.jpg";
// import img30 from "./image/step3.jpg";
// import img29 from "./image/ProjectSteps_002.png";
// import img30 from "./image/ProjectSteps_003.png";

import HeaderNavbar from "./header-navbar";
import React, { useState } from "react";
import { faWhatsapp, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faChevronUp,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col, Container, Grid } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
const ourFeaturesCarouselResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const ourOurClientSayResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function App() {
  const whateWeOfferarr = [
    { id: 1, content: "Generate simple and easy bills.", img: CarousalFirst },
    {
      id: 2,
      content: "Your customers can now Scan and Order.",
      img: CarousalSecond,
    },
    { id: 3, content: "Keep your customer satisfied.", img: CarousalThird },
    {
      id: 4,
      content: "Loyalty programs made easy to retain your customers.",
      img: CarousalFourth,
    },
    {
      id: 5,
      content: "Maintain your stocks with just one click.",
      img: CarousalFifth,
    },
  ];

  const onlineShoparr = [
    {
      id: 1,
      head: "Create online store in 30 seconds",
      body: "You only need your mobile phone to create online store.",
      img: Segment1,
    },
    {
      id: 2,
      head: "Send e-bill through SMS",
      body: "Create e-bill in 10 seconds and send it through SMS. Save money and Save paper.",
      img: Segment2,
    },
    {
      id: 3,
      head: "Increase sales with Loyalty program",
      body: "Track customer loyalty points and share loyalty details with customers.",
      img: Segment3,
    },
    {
      id: 4,
      head: "Sales Report",
      body: "See daily, monthly and yearly sales on your mobile with single click.",
      img: Segment4,
    },
  ];
  const star = [1, 2, 3, 4, 5];

  return (
    <div className="body full-width float-left">
      <div className="header" id="home">
        <HeaderNavbar />
      </div>
      <div>
        <About />
        <WhatsApp />
        <ProjectSteps />
        <Article />
        <Project />
        {/* <Service /> */}
        <News />
        <Review />
        <ScrollArrow />
      </div>
      <div>
        {/* <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-7">
                <div className="vertical-space-120"></div>
                <div className="vertical-space-50"></div>
                <h3>All in one Business App</h3>
                <p >Ghoshak is the only one app that you will require to manage your business.</p>
                <a href="https://play.google.com/store/apps/details?id=com.ghoshak_owner" id="download-btn-Business" >
                  <Button className="downloadbtn" style={{ position: "absolute" }}>
                    <FontAwesomeIcon className="iconbtn" icon={faGooglePlay}></FontAwesomeIcon>{" "}DOWNLOAD NOW
                  </Button>
                </a>
                <br />
                <a href="https://api.whatsapp.com/send/?phone=917401066066&text&app_absent=0" className="whatsapp">
                  <Button className="whatsappbtn">
                    <FontAwesomeIcon className="iconbtn" icon={faWhatsapp} ></FontAwesomeIcon>{" "}Contact us on Whatsapp
                  </Button>
                </a>
              </div>
              <div className="col-5">
                <div className="img-box">
                  <img src={banner_img} className="full-width" alt="category" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-app-features" id="what-we-offer">
          <div className="container">
            <div className="vertical-space-60"></div>
            <h4 className="df-hr text-center">How does it help your Business?</h4>
            <p className="text-center content">All your business operations in one place.</p>
            <Carousel className="our-features-carousel" responsive={ourFeaturesCarouselResponsive}>
              {
                whateWeOfferarr.map(value => {
                  return (
                    <div className="our-features-carousel-box" key={value.id}>
                      <div className="img-box">
                        <img src={value.img} className="full-width" alt="category" />
                      </div>
                      <h5>{value.content}</h5>
                    </div>
                  );
                })
              }
            </Carousel>
            <div className="vertical-space-30"></div>
          </div>
        </div>
        <div className="create-info">
          <div className="container">
            {
              onlineShoparr.map(value => {
                return (
                  <div key={value.id}>
                    <div className="vertical-space-120"></div>
                    <div className="row create-info-box">
                      <div className="col-sm-6">
                        <div className="img-box">
                          <img src={value.img} className="full-width" alt="category" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="detail-box">
                          <h4 className="df-hr">{value.head}</h4>
                          <p className="content">{value.body}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            }
            <div className="vertical-space-90"></div>
          </div>
        </div>

        <div className="WhatOurClientSay">
          <div className="container">
            <div className="vertical-space-70"></div>
            <h4 className="df-hr">What Our Clients Say</h4>
            <div className="vertical-space-30"></div>
            <div className="row">
              <div className="col-sm-4">
                <div className="img-box">
                  <img src={JayanthiAgency} className="full-width" alt="category" />
                </div>
                <div className="detail-box">
                  <h4>Jayanthi Agency</h4>
                  <p>It is a simple and easy app that helps me manage my business. It helps me manage my online orders, understand my sales better and retain my customers.</p>
                  <ul className="list-unstyled">
                    {
                      star.map(star => <li key={star}><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>)
                    }
                  </ul>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="img-box">
                  <img src={GourmentBiriyani} className="full-width" alt="category" />
                </div>
                <div className="detail-box">
                  <h4>Gourment Biriyani</h4>
                  <p>Due to the pandemic, there was a major downfall in our walk-ins. But, I was able to Pick-up my business by converting our dine-in. Customers to online orders using Ghoshak App. The product upload and e-menu features are very simple to use.</p>
                  <ul className="list-unstyled">
                    {
                      star.map(star => <li key={star}><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>)
                    }
                  </ul>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="img-box">
                  <img src={SpectrumNetwork} className="full-width" alt="category" />
                </div>
                <div className="detail-box">
                  <h4>Spectrum Network</h4>
                  <p>Ghoshak app is usefull for my shop , i can update the services provided and my shop offer from mobile, and billing also very user friendly.</p>
                  <ul className="list-unstyled">
                    {
                      star.map(star => <li key={star}><FontAwesomeIcon icon={faStar}></FontAwesomeIcon></li>)
                    }
                  </ul>
                </div>
              </div>
            </div>
            <div className="vertical-space-90"></div>
          </div>
        </div>

        <div className="contactUs">
          <div className="vertical-space-30"></div>
          <div className="row">
            <div className="col-sm-4">
              <div className="contactUs-box left">
                <ul className="list-unstyled">
                  <li>SALOON AND BEAUTY PARLOUR</li>
                  <li>TEXTILE SHOP</li>
                  <li>TOY SHOP</li>
                  <li>GROCERY SHOP</li>
                  <li>RESTAURANT</li>
                  <li>FOOTWEAR</li>
                  <li>MOBILE SHOP</li>
                  <li>SPA</li>
                </ul>
                <div className="img-box">
                  <img src={ShopType1} className="full-width" alt="category" />
                  <img src={ShopType2} className="full-width" alt="category" />
                  <img src={ShopType3} className="full-width" alt="category" />
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="detail-box">
                <h4 className="df-hr">Contact Us</h4>
                <h5>EMAIL ADDRESS</h5>
                <p>support@ghoshak.com</p>
                <h5>PHONE NUMBER</h5>
                <p>+91 7401066066</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="contactUs-box right">
                <div className="img-box">
                  <img src={ShopType4} className="full-width" alt="category" />
                  <img src={ShopType5} className="full-width" alt="category" />
                  <img src={ShopType6} className="full-width" alt="category" />
                </div>
                <ul className="list-unstyled">
                  <li>ELECTRONIC SHOP</li>
                  <li>JEWELLERY</li>
                  <li>HARDWARE</li>
                  <li>TUITION CENTRE</li>
                  <li>DOCTOR CLINIC</li>
                  <li>MEDICAL SHOP</li>
                  <li>MEAT SHOP</li>
                  <li>TRAVEL AND CABS</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="vertical-space-60"></div>
        </div>  */}
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;

const WhatsApp = () => {
  const [showwhatsppScroll, setShowwhatsppScroll] = useState(false);

  const checkwhatsppScrollTop = () => {
    if (!showwhatsppScroll && window.pageYOffset > 300) {
      setShowwhatsppScroll(true);
    } else if (showwhatsppScroll && window.pageYOffset <= 300) {
      setShowwhatsppScroll(false);
    }
  };

  window.addEventListener("scroll", checkwhatsppScrollTop);
  return (
    <div style={{ display: showwhatsppScroll ? "flex" : "none" }}>
      <a
        style={{ color: "white" }}
        href="https://api.whatsapp.com/send/?phone=917401066066&text&app_absent=0"
        className="float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faWhatsapp} className="my-float " />
        <span class="tooltiptext">BOOK A DEMO</span>
      </a>
    </div>
  );
};
const ScrollArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 200) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 200) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <FontAwesomeIcon
      icon={faChevronUp}
      className="scrollTop"
      onClick={scrollTop}
      style={{ height: 52, display: showScroll ? "flex" : "none" }}
    />
  );
};
const About = (whatsappNumber) => {
  return (
    <div className="wholeContainer" id="home">
      <div className="aboutCont">
        <div className="contentDiv">
          <div className="containerHeading">
            <h2>Technology to manage all the Business Operations.</h2>
          </div>

          <div className="flexDiv">
            <div className="whatsappDiv">
              <button
                className="demobtn"
                style={{ backgroundColor: "#f18d49" }}
                onClick={() =>
                  window.open(
                    // "https://api.whatsapp.com/send/?phone=917401066066&text&app_absent=0"
                    "tel:917401066066"
                  )
                }
              >
                BOOK A DEMO
              </button>
            </div>
            <div className="whatsappDiv">
              <img
                src={img2}
                width={200}
                height={60}
                className="aboutDownloadBtn"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.ghoshak_owner&hl=en_IN&gl=US",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="imageDivision">
          <div className="imgStyle">
            <img src={img3} height={500} width={500} quality={100} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Project = () => {
  return (
    <div className="wholeContainer">
      <div className="projectContainer">
        {/* <div className="row g-0" style={{flexWrap:"nowrap"}}>
          <div className="col-lg-4">
            <div className="firstheadingheader">
              <p>Create your website in 3 simple steps in 10 minutes</p>
            </div>
          </div>
          <div className="col-lg-4">
            {" "}
            <div className="firstheadingheader">
              <p>Start selling with your online store.</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="firstheadingheader">
              <p>POS for your business. Call to schedule visit</p>
            </div>
          </div>
        </div> */}
        <div className="projectbtndiv">
          <button
            className="bookbtn"
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send/?phone=917401066066&text&app_absent=0"
              )
            }
          >
            BOOK A DEMO
          </button>
        </div>
      </div>
    </div>
  );
};

const Article = () => {
  const responsive = {
    largedesktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1200, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 900 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 900, min: 0 },
      items: 1,
    },
  };

  const cardData = [
    {
      id: 1,
      imageUrl: img38,
      heading: "PET SUPPLIES",
    },
    {
      id: 2,
      imageUrl: img39,
      heading: "RESTAURANTS",
    },
    {
      id: 3,
      imageUrl: img8,
      heading: "INTERIOR DESIGNER",
    },
    {
      id: 4,
      imageUrl: img9,
      heading: "RETAIL STORE",
    },
    {
      id: 5,
      imageUrl: img10,
      heading: "FASHION STORE",
    },
    {
      id: 6,
      imageUrl: img11,
      heading: "ARCHITECTS",
    },
  ];
  return (
    <>
      <div className="wholeContainer" id="designs">
        <div className="articleHeading">
          <p>DESIGN FOR EVERY BUSINESS</p>
        </div>
        <div className="articleFlexContainer">
          <div className="carouselDiv">
            <Carousel
              swipeable={true}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true}
              infinite={true}
              arrows={true}
              sliderClass="slider"
              autoPlay={true}
              autoPlaySpeed={2500}
              keyBoardControl={true}
              // renderButtonGroupOutside={true}
              // removeArrowOnDeviceType={["tablet", "mobile"]}
              // customButtonGroup={<ButtonGroup />}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding"
            >
              {cardData.map((data) => (
                <div className="cardContainerDiv" key={data.id}>
                  <div>
                    <div className="cardImgHead">
                      <img
                        src={data.imageUrl}
                        alt="p3"
                        width="280px"
                        height="500px"
                      />
                    </div>
                    <div className="heading">
                      <p>{data.heading}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};
const ButtonGroup = ({ next, previous, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="carousel-button-group">
      <div className="rightArrowDiv">
        <i className="rightArrow" onClick={next}>
          <img src={img25} />
        </i>
      </div>
      <div className="leftArrowDiv">
        <i className="leftArrow" onClick={previous}>
          <img src={img24} />
        </i>
      </div>
    </div>
  );
};
// const Service = (props) => {
//   const [isChecked, setIsChecked] = useState(false);
//   const handleOnChange = () => {
//     setIsChecked(!isChecked);
//   };
//   return (
//     <div className="wholeContainer" style={{ backgroundColor: "#fff" }}>
//       <div className="serviceHeader">
//         <div className="wholeContainerHeading">
//           <h2>SELECT YOUR BUSINESS PLAN</h2>
//         </div>
//         <div className="subTitle">
//           <p>Plan starts from 499/- per year</p>
//         </div>
//       </div>
//       <div className="containerbox">
//         <div className="row g-0">
//           <div className="col-lg-7">
//             <div className="topping">
//               <input
//                 type="checkbox"
//                 id="topping"
//                 name="topping"
//                 value="Paneer"
//                 checked={isChecked}
//                 onChange={handleOnChange}
//               />
//               <p className="checkboxinput">WHATSAPP STORE get orders online </p>
//             </div>
//             <div className="topping">
//               <input
//                 type="checkbox"
//                 id="topping"
//                 name="topping"
//                 value="Paneer"
//                 checked={isChecked}
//                 onChange={handleOnChange}
//               />
//               <p className="checkboxinput">
//                 ONLINE STORE with payment and delivery
//               </p>
//             </div>
//             <div className="topping">
//               <input
//                 type="checkbox"
//                 id="topping"
//                 name="topping"
//                 value="Paneer"
//                 checked={isChecked}
//                 onChange={handleOnChange}
//               />
//               <p className="checkboxinput">BILLING - Mobile App</p>
//             </div>
//             <div className="topping">
//               <input
//                 type="checkbox"
//                 id="topping"
//                 name="topping"
//                 value="Paneer"
//                 checked={isChecked}
//                 onChange={handleOnChange}
//               />
//               <p className="checkboxinput">LOYALTY PROGRAM</p>
//             </div>
//             <div className="topping">
//               <input
//                 type="checkbox"
//                 id="topping"
//                 name="topping"
//                 value="Paneer"
//                 checked={isChecked}
//                 onChange={handleOnChange}
//               />
//               <p className="checkboxinput">
//                 POS with out hardware and customer app{" "}
//               </p>
//             </div>
//             <div className="topping">
//               <input
//                 type="checkbox"
//                 id="topping"
//                 name="topping"
//                 value="Paneer"
//                 checked={isChecked}
//                 onChange={handleOnChange}
//               />
//               <p className="checkboxinput">
//                 POS with tab,printer,scanner,customer app
//               </p>
//             </div>
//             <div className="topping">
//               <input
//                 type="checkbox"
//                 id="topping"
//                 name="topping"
//                 value="Paneer"
//                 checked={isChecked}
//                 onChange={handleOnChange}
//               />
//               <p className="checkboxinput">
//                 POS with tab,printer,scanner,cashbox,customer app
//               </p>
//             </div>
//             <div className="topping">
//               <input
//                 type="checkbox"
//                 id="topping"
//                 name="topping"
//                 value="Paneer"
//                 checked={isChecked}
//                 onChange={handleOnChange}
//               />
//               <p className="checkboxinput">APPOINTMENT BOOKING with payment</p>
//             </div>
//             <div className="topping">
//               <input
//                 type="checkbox"
//                 id="topping"
//                 name="topping"
//                 value="Paneer"
//                 checked={isChecked}
//                 onChange={handleOnChange}
//               />
//               <p className="checkboxinput">
//                 MARKETING Facebook, Instagram and Google shop
//               </p>
//             </div>
//             <div className="topping">
//               <input
//                 type="checkbox"
//                 id="topping"
//                 name="topping"
//                 value="Paneer"
//                 checked={isChecked}
//                 onChange={handleOnChange}
//               />
//               <p className="checkboxinput">
//                 WEBSITE for business with online store,
//                 payment, delivery
//               </p>
//             </div>
//             <div className="topping">
//               <input
//                 type="checkbox"
//                 id="topping"
//                 name="topping"
//                 value="Paneer"
//                 checked={isChecked}
//                 onChange={handleOnChange}
//               />
//               <p className="checkboxinput">
//                 BUSINESS MANAGER to grow your business online
//               </p>
//             </div>
//           </div>
//           <div className="col-lg-5 align-self-center">
//             <div>
//               <p>Total Price: </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
const News = () => {
  return (
    <>
      <div className="wholeContainer">
        <div className="wholeContainerHeading">
          <h2>IN THE NEWS</h2>
        </div>
        <div className="newsContainer">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="cardContainer">
                <div className="newsContainerImg">
                  <img
                    src={img35}
                    width={150}
                    height={50}
                    onClick={() =>
                      window.open(
                        "https://yourstory.com/smbstory/amazon-webs-services-smb-digitisation-msme-technology-ghoshak/amp",
                        "_blank"
                      )
                    }
                  />
                </div>
                <div className="newsContainerpara">
                  <p>
                    A platform to digitise SMBs, reports 50K downloads in less
                    than a year
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="cardContainer">
                <div className="newsContainerImg">
                  <img
                    src={img34}
                    width={150}
                    height={50}
                    onClick={() =>
                      window.open(
                        "https://www.cxooutlook.com/how-digitalisation-eased-the-pandemic-for-small-businesses/",
                        "_blank"
                      )
                    }
                  />
                </div>
                <div className="newsContainerpara">
                  <p>
                    How Digitalisation Eased the Pandemic for Small Businesses
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="cardContainer">
                <div className="newsContainerImg">
                  <img
                    src={img36}
                    width={150}
                    height={50}
                    onClick={() =>
                      window.open(
                        "https://swiftnliftmagazine.tumblr.com/post/652325024624164864/the-main-focus-is-to-connects-people-with-great",
                        "_blank"
                      )
                    }
                  />
                </div>
                <div className="newsContainerpara">
                  <p>
                    Mobile solutions to digitize small businesses sales, service
                    and marketing
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="cardContainer">
                <div className="newsContainerImg">
                  <img
                    src={img37}
                    width={120}
                    height={100}
                    onClick={() =>
                      window.open(
                        "https://startuptalky.com/hiring-tips-by-ghoshak-founder-rajesh-subramanian/",
                        "_blank"
                      )
                    }
                  />
                </div>
                <div className="newsContainerpara">
                  <p>Game of Hiring: Bootstrapped Edition</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cardContainer">
                <div className="newsContainerImg">
                  <img
                    src={img40}
                    width={200}
                    height={200}
                    onClick={() =>
                      window.open(
                        "https://www.financialexpress.com/industry/sme/ghoshak-helping-small-businesses-go-digital/2351198/",
                        "_blank"
                      )
                    }
                    style={{ marginTop: "-50px" }}
                  />
                </div>
                <div className="newsContainerpara">
                  <p>Helping small businesses go digital</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Review = () => {
  const customer = [
    {
      id: 1,
      image: img20,
      shopname: "Jayanthi Agency",
      shopdesp:
        "Conducting business has now become easy and manageable with Ghoshak. I can now take orders online and based on the purchases made, I can understand the needs of my customers and introduce new products accordingly. Also, I like that I can type in vernacular language which helps my customers also read easily.",
    },
    {
      id: 2,
      image: img21,
      shopname: "Gourment Biriyani",
      shopdesp:
        "Pandemic impacted the customer walk-ins at our restaurant. The only resort was to take online delivery orders and Ghoshak helped us do the same. We were able to upload the menu easily and take online orders. Post the unlock we were able to convert those customers to dine-in by offering various offers and discounts through Ghoshak.",
    },
    {
      id: 3,
      image: img22,
      shopname: "Spectrum Network",
      shopdesp:
        "Ghoshak’s Loyalty program has helped my business a lot especially when it’s quite easy to use. I use the loyalty and reward points on the different purchases depending upon the ticket size of the item. This motivates my customers to revisit and buy again. ",
    },
  ];
  return (
    <div className="wholeContainer" id="testimonials">
      <div className="reviewContainer">
        <div className="wholeContainerHeading">
          <h2>Customer Testimonials</h2>
        </div>
      </div>
      <div className="review ">
        <div>
          <div className="row justify-content-center no-gutters ">
            {customer.map((value) => (
              <div
                className="col-9 col-sm-5 col-md-3 testimonialsDesignThreeContentRow"
                style={{ marginBottom: 20 }}
              >
                <div className="row text-align-center ">
                  <div className="col imageOfClient">
                    <img src={value.image} height={80} width={80} />
                  </div>
                  <div className=" col twoContent">
                    <h4 className="shopName">{value.shopname}</h4>
                    <p className="stars">
                      {[...Array(5)].map((star) => {
                        return <FontAwesomeIcon icon={faStar} />;
                      })}
                    </p>
                  </div>
                </div>
                <div className="quotationDivTop">
                  <img
                    className="quotesImage"
                    src={img26}
                    height={20}
                    width={20}
                  />
                </div>
                <p className="shopDescription"> {value.shopdesp}</p>
                <div className="quotationDivDown">
                  <img
                    className="quotesImage"
                    src={img27}
                    height={20}
                    width={20}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectSteps = () => {
  return (
    <div className="wholeContainer" id="features">
      <div className="threeSectionContainer">
        <div className="row">
          <div className="col-lg-6">
            <h5 style={{ color: "#f18d49" }}>ONLINE STORE</h5>
            <div className="containerHeading">
              <h2>Setup up online store and sell everywhere</h2>
            </div>
            <p>
              Sell your products pan India and International with the online
              store and customer support in the local language
            </p>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Choose from a pool of professional designs to match your brand
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Get a secure payment system
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Smooth deliveries with our delivery partners
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                No additional commissions
              </p>
            </div>
            <button className="learnbtn">Learn More</button>
          </div>
          <div className="col-lg-6">
            <div className="threeSectionContainerImg">
              <img src={img33} alt="App" width="450" height="450" />
            </div>
          </div>
        </div>
      </div>
      <div className="threeSectionContainer">
        <div className="row">
          <div className="col-lg-6 order-sm-last order-last order-lg-first">
            <div className="threeSectionContainerImg">
              <img src={img32} alt="App" width="450" height="450" />
            </div>
          </div>
          <div className="col-lg-6">
            <h5 style={{ color: "#f18d49" }}>WEBSITE</h5>
            <div className="containerHeading">
              <h2>Customers trust businesses with a website</h2>
            </div>
            <p>Get your brand online and be easily found by the customers</p>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Get your website domain free for the first year*
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Easily control your website content from your phone
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Choose from 100+ design templates
              </p>
            </div>
            {/* <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Control website content using your mobile phone anytime and from
                anywhere
              </p>
            </div> */}
            <button className="learnbtn">Learn More</button>
          </div>
        </div>
      </div>
      <div className="threeSectionContainer">
        <div className="row">
          <div className="col-lg-6">
            <h5 style={{ color: "#f18d49" }}>POS</h5>
            <div className="containerHeading">
              <h2>
                Fully Integrated (Point Of Sales) POS with the omnichannel
                retail solution{" "}
              </h2>
            </div>
            <p>
              Cloud-based POS solution to increase customer satisfaction,
              improve business efficiency with intelligent reports
            </p>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Paperless invoice
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Inventory management
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Automatically updates online store
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Loyalty program for both offline & online shoppers
              </p>
            </div>
            <button className="learnbtn">Learn More</button>
          </div>
          <div className="col-lg-6 ">
            <div className="threeSectionContainerImg">
              <img src={img29} alt="App" width="450" height="450" />
            </div>
          </div>
        </div>
      </div>
      <div className="threeSectionContainer">
        <div className="row">
          <div className="col-lg-6 order-sm-last order-last order-lg-first">
            <div className="threeSectionContainerImg">
              <img src={img30} alt="App" width="450" height="450" />
            </div>
          </div>
          <div className="col-lg-6">
            <h5 style={{ color: "#f18d49" }}>MARKETING</h5>
            <div className="containerHeading">
              <h2>Sell and market everywhere with one app</h2>
            </div>
            <p>
              Upload products on the Ghoshak app and get visibility on Google
              Business, Facebook Shop, WhatsApp Shop, Amazon, and other
              marketplaces
            </p>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Products get listed on multiple online shopping marketplaces
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                One dashboard to manage all the marketplaces
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Expert advice to promote your online products
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Reach your customers with targeted marketing strategies
              </p>
            </div>
            <button className="learnbtn">Learn More</button>
          </div>
        </div>
      </div>
      <div className="threeSectionContainer">
        <div className="row">
          <div className="col-lg-6">
            <h5 style={{ color: "#f18d49" }}>ONLINE BOOKINGS</h5>
            <div className="containerHeading">
              <h2>
                Improve customer experience with quick online appointments
              </h2>
            </div>
            <p>
              Get the competitive edge by booking appointments from the website
              directly
            </p>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Manage bookings, reschedule, and cancellation from one place
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p>
                <span style={{ paddingRight: "10px" }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#f18d49" }}
                  />
                </span>
                Send automated reminders through SMS
              </p>
            </div>
            <button className="learnbtn">Learn More</button>
          </div>
          <div className="col-lg-6">
            <div className="threeSectionContainerImg">
              <img src={img31} alt="App" width="450" height="450" />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="threeSection">
        <div className="threeSectionContainer">
          <div className="sectionOne">
            <div className="row no-gutters">
              <div className=" order-sm-last order-last order-lg-first column-33 ">
                <div className="parent ">
                <img
                  src={img28}
                  alt="App"
                  className="firstImg"
                  width="230"
                  height="291"
                />
                </div>
              </div>
              <div className=" column-66 ">
                <h3 className="steps">STEP 1</h3>
                <h1 className="stepsHeading">REGISTER YOUR DOMAIN</h1>
                <span className="stepshr">
                  <hr />
                </span>
                <p className="stepsDescription">
                  A unique domain name can help your business standout. Choose
                  your short and memorable domain recognised by your customer in
                  a click.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="threeSectionContainer">
          <div className="sectionTwo">
            <div className="row no-gutters">
              <div className=" text-sm-left  text-md-left text-lg-right ccolumn-66">
                <h3 className="stepsTwo">STEP 2</h3>
                <h1 className="stepsTwoHeading">
                  PICK A THEME AND CREATE YOUR CONTENT
                </h1>
                <span className="stepsTwoHr">
                  <hr />
                </span>
                <p className="stepsTwoDescription">
                  A Business is “What we do and How we do”. Add your desired
                  sections and start adding your contents within few minutes.
                </p>
              </div>
              <div className="ccolumn-33">
                <img src={img29} 
                width="354" 
                className="secondImg"
                height="266" />
              </div>
            </div>
          </div>
        </div>
        
        <div className="threeSectionContainer">
          <div className="sectionThree">
            <div className="row  no-gutters">
              <div className=" order-sm-last order-last order-lg-first column-33">
              <div className="parent">
                <img
                  src={img30}
                  alt="App"
                  className="lastImg"
                  width="230"
                  height="291"
                />
                </div>
              </div>
              <div className="column-66">
                <h3 className="steps">STEP 3</h3>
                <h1 className="stepsHeading">MAKE YOUR WEBSITE LIVE</h1>
                <span className="stepshr">
                  <hr />
                </span>
                <p className="stepsDescription">
                  80% of people trust businessess with a website. Your website
                  is ready to live in a minute
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
