import React from 'react';
import Footer from "./footer"
import HeaderNavbar from "./header-navbar"
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faEnvelope, faMobile } from "@fortawesome/free-solid-svg-icons";
import img28 from "./image/whatlogo.png";

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
function App() {
  return (
    <div className="body full-width float-left">
      <div className="header other-page-header" id="home">
        <HeaderNavbar />
      </div>
      <div className="contact-us">
        <div className="container">
          <div className="vertical-space-60"></div>
          <h4 className="df-hr text-center">Contact <span className='df-color'>Us</span></h4>
          <div className="vertical-space-30"></div>
          <div className="row">
            <div className="col-md-4">
              <div className="contact-us-box" >
                <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>
                <h5 className="Address">Address</h5>
                <address>Zoryboard Software Solutions<br />
                            7A, Eldorado Building,<br />
                            Nungambakkam High Rd,<br />
                            Thousand Lights West,<br />
                            Chennai, TN 600034
                </address>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-us-box" >
                <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                <h5 className="Address">Email</h5>
                <p>support@ghoshak.com</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-us-box" >
                <FontAwesomeIcon icon={faMobile}></FontAwesomeIcon>
                <h6 >PHONE NUMBER</h6>
                <p>+91 7401066066</p>
                <div className="whatsppIcon">
                <img
                src={img28}
                alt="mobile"
                width={30}
                height={30}
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=917401066066&text&app_absent=0"
                  )
                }
              />
                  </div>
              </div>
            
            </div>
          </div>
          <div className="vertical-space-60"></div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
