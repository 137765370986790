import React from "react";

function ZoomForGhoshakInstall() {
  return (
    <div className="body full-width float-left">
      <p style={{ textAlign: "center", fontSize: "45px" }}>
        Zoom for Ghoshak installed
      </p>
    </div>
  );
}

export default ZoomForGhoshakInstall;
