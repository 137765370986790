import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import TermsAndConditionsCustomer from "./t&c_customer";
import TermsAndConditionsMerchant from "./t&c_merchant";
import TermsAndConditionsMerchantCoupon from "./t&c_merchant_coupon";
import FaqCustomer from "./faq_customer";
import FaqMerchant from "./faq_merchant";
import PrivacyPolicy from "./privacy_policy";
import ZoomForGhoshakInstall from "./zoomForGhoshakInstalled";
import ZoomForGhoshakUnInstall from "./zoomForGhoshakUnInstalled";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Home from "./Home";
import AboutUs from "./about-Us";
import OurCareer from "./career";
import ContactUs from "./conatct-us";
import Footer from "./footer";
import HeaderNavbar from "./header-navbar";

import * as serviceWorker from "./serviceWorker";

function Index() {
  return <Home />;
}

function CustomerTerms() {
  return <TermsAndConditionsCustomer />;
}

function MerchantTerms() {
  return <TermsAndConditionsMerchant />;
}

function About_Us() {
  return <AboutUs />;
}

function Career() {
  return <OurCareer />;
}

function Contact_Us() {
  return <ContactUs />;
}

function ZoomInstall() {
  return <ZoomForGhoshakInstall />;
}

function ZoomUnInstall() {
  return <ZoomForGhoshakUnInstall />;
}

ReactDOM.render(
  <React.StrictMode>
    {/* <App /> */}

    {/* <TermsAndConditionsCustomer /> */}

    {/* <TermsAndConditionsMerchant/> */}

    {/* <TermsAndConditionsMerchantCoupon /> */}

    {/* <FaqCustomer /> */}

    {/* <FaqMerchant /> */}

    {/* <PrivacyPolicy /> */}

    <>
      <Router>
        <>
          <Route exact path="/merchantTerms">
            <MerchantTerms />
          </Route>
          <Route exact path="/header-navbar">
            <HeaderNavbar />
          </Route>
          <Route exact path="/customerTerms">
            <CustomerTerms />
          </Route>
          <Route exact path="/aboutUs">
            <AboutUs />
          </Route>
          <Route exact path="/career">
            <OurCareer />
          </Route>
          <Route exact path="/contactUs">
            <Contact_Us />
          </Route>
          <Route exact path="/privacyPolicy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/zoomForGhoshakInstall">
            <ZoomInstall />
          </Route>
          <Route exact path="/zoomForGhoshakUnInstall">
            <ZoomUnInstall />
          </Route>
          <Route exact path="/">
            <Index />
          </Route>
        </>
      </Router>
    </>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// export default function App() {
//   return (

//   );
// }
