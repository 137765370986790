import  React, {useState} from 'react';
import Footer from "./footer"
import HeaderNavbar from "./header-navbar"
import Carousel from "react-multi-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faGooglePlusG,
  faTwitter,
  faYoutube,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { Navbar,Nav,InputGroup,FormControl,Button, Modal, Row, Container, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const ourFeaturesCarouselResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};

const ourOurClientSayResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function App() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="body full-width float-left">
      <div  className="header other-page-header" id="home">
        <HeaderNavbar />
      </div>
      <div class="terms_conditions">
        <div class="container">
          <div class="vertical-space-30"></div>
          <h3>PRIVACY POLICY</h3>
          <p>
            THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF AN
            ELECTRONIC CONTRACT FORMED UNDER THE INFORMATION TECHNOLOGY ACT, 2000
            AND THE RULES MADE THEREUNDER AND THE AMENDED PROVISIONS PERTAINING TO
            ELECTRONIC DOCUMENTS / RECORDS IN VARIOUS STATUTES AS AMENDED BY THE
            INFORMATION TECHNOLOGY ACT, 2000. THIS PRIVACY POLICY DOES NOT REQUIRE
            ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.
          </p>
          <p>
            THIS PRIVACY POLICY IS A LEGALLY BINDING DOCUMENT BETWEEN YOU AND THE
            AUTHOR (BOTH TERMS DEFINED BELOW). THE TERMS OF THIS PRIVACY POLICY
            WILL BE EFFECTIVE UPON YOUR ACCEPTANCE OF THE SAME (DIRECTLY OR
            INDIRECTLY IN ELECTRONIC FORM, BY CLICKING ON THE I ACCEPT TAB OR BY
            USE OF THE WEBSITE OR BY OTHER MEANS) AND WILL GOVERN THE RELATIONSHIP
            BETWEEN YOU AND AUTHOR FOR YOUR USE OF THE WEBSITE (DEFINED BELOW).
          </p>
          <p>
            THIS DOCUMENT IS PUBLISHED AND SHALL BE CONSTRUED IN ACCORDANCE WITH
            THE PROVISIONS OF THE INFORMATION TECHNOLOGY (REASONABLE SECURITY
            PRACTICES AND PROCEDURES AND SENSITIVE PERSONAL DATA OF INFORMATION)
            RULES, 2011 UNDER INFORMATION TECHNOLOGY ACT, 2000; THAT REQUIRE
            PUBLISHING OF THE PRIVACY POLICY FOR COLLECTION, USE, STORAGE AND
            TRANSFER OF SENSITIVE PERSONAL DATA OR INFORMATION.
          </p>
          <p>
            PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY USING THE APP, YOU
            INDICATE THAT YOU UNDERSTAND, AGREE AND CONSENT TO THIS PRIVACY
            POLICY. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY,
            PLEASE DO NOT USE THIS WEBSITE. YOU HEREBY PROVIDE YOUR UNCONDITIONAL
            CONSENT OR AGREEMENTS TO THE AUTHOR AS PROVIDED UNDER SECTION 43A AND
            SECTION 72A OF INFORMATION TECHNOLOGY ACT, 2000.
          </p>
          <p>
            Zoryboard Software Solutions Private Limited. (the “Author”) is the
            author and publisher of the software and applications provided by the
            Author, including but not limited to the mobile application ‘Ghoshak’
            (together with the Website, referred to as the “Services”).
          </p>
          <p>
            This privacy policy (<strong>"Privacy Policy"</strong>) explains how
            we collect, use, share, disclose and protect Personal information
            about the Users of the Services, including the business users, the
            end-users, and the visitors of App (jointly and severally referred to
            as “you” or “Users” in this Privacy Policy). We created this Privacy
            Policy to demonstrate our commitment to the protection of your privacy
            and your personal information. Your use of and access to the Services
            is subject to this Privacy Policy and our Terms of Use. Any
            capitalized term used but not defined in this Privacy Policy shall
            have the meaning attributed to it in our Terms of Use.
          </p>
          <p>
            BY USING THE SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU
            WILL BE DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE PRACTICES
            AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE TO BE BOUND BY
            THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, USE AND
            SHARING, DISCLOSURE OF YOUR INFORMATION AS DESCRIBED IN THIS PRIVACY
            POLICY. WE RESERVE THE RIGHT TO CHANGE, MODIFY, ADD OR DELETE PORTIONS
            OF THE TERMS OF THIS PRIVACY POLICY, AT OUR SOLE DISCRETION, AT ANY
            TIME. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY AT ANY TIME, DO NOT
            USE ANY OF THE SERVICES OR GIVE US ANY OF YOUR INFORMATION. IF YOU USE
            THE SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN
            ENTITY (SUCH AS YOUR EMPLOYER), YOU REPRESENT THAT YOU ARE AUTHORISED
            BY SUCH INDIVIDUAL OR ENTITY TO (I) ACCEPT THIS PRIVACY POLICY ON SUCH
            INDIVIDUAL’S OR ENTITY’S BEHALF, AND (II) CONSENT ON BEHALF OF SUCH
            INDIVIDUAL OR ENTITY TO OUR COLLECTION, USE AND DISCLOSURE OF SUCH
            INDIVIDUAL’S OR ENTITY’S INFORMATION AS DESCRIBED IN THIS PRIVACY
            POLICY.
          </p>
          <h4>Privacy Policy</h4>
          <p>This Privacy Policy is published in compliance with, inter alia:</p>
          <ol type="i">
            <li>Section 43A of the Information Technology Act, 2000;</li>
            <li>
              Regulation 4 of the Information Technology (Reasonable Security
              Practices and Procedures and Sensitive Personal Information) Rules,
              2011 (the “SPI Rules”);
            </li>
            <li>
              Regulation 3(1) of the Information Technology (Intermediaries
              Guidelines) Rules, 2011. This Privacy Policy states the following:
            </li>
            <li>
              The type of information collected from the Users, including Personal
              Information (as defined in paragraph 2 below) and Sensitive Personal
              Data or Information (as defined in paragraph 2 below) relating to an
              individual;
            </li>
            <li>
              The purpose, means and modes of collection, usage, processing,
              retention and destruction of such information; and
            </li>
            <li>
              How and to whom the Author will disclose such information.
            </li>
          </ol>
          <p>
            By providing us your Information or by making use of the facilities
            provided by the Website, You hereby consent to the collection,
            storage, processing and transfer of any or all of Your Personal
            Information and Non-Personal Information by the Author as specified
            under this Privacy Policy. You further agree that such collection,
            use, storage and transfer of Your Information shall not cause any loss
            or wrongful gain to you or any other person. This Privacy Policy shall
            be read together with the respective Terms Of Use or other terms and
            conditions.
          </p>
          <h4>Collection of Personal Information</h4>
          <p>
            Generally, some of the Services require us to know who you are so that
            we can best meet your needs. When you access the Services, or through
            any interaction with us via emails, telephone calls or other
            correspondence, we may ask you to voluntarily provide us with certain
            information that personally identifies you or could be used to
            personally identify you. You hereby consent to the collection of such
            information by the Author. Without prejudice to the generality of the
            above, information collected by us from you may include (but is not
            limited to) the following:
          </p>
          <ol type="i">
            <li>contact data (such as your email address and phone number);</li>
            <li>
              demographic data (such as your gender, your date of birth and your
              pin code);
            </li>
            <li>
              data regarding your usage of the services and history of the
              appointments made by or with you through the use of Services;
            </li>
            <li>
              insurance data (such as your insurance carrier and insurance plan);
            </li>
            <li>
              other information that you voluntarily choose to provide to us (such
              as information shared by you with us through emails or letters. The
              information collected from you by the Author may constitute
              ‘personal information’ or ‘sensitive personal data or information’
              under the SPI Rules.
            </li>
          </ol>
          <p>
            <strong>“Personal Information”</strong> is defined under the SPI Rules
            to mean any information that relates to a natural person, which,
            either directly or indirectly, in combination with other information
            available or likely to be available to a body corporate, is capable of
            identifying such person. The SPI Rules further define “Sensitive
            Personal Data or Information” of a person to mean personal information
            about that person relating to:
          </p>
          <ol type="i">
            <li>passwords;</li>
            <li>
              financial information such as bank accounts, credit and debit card
              details or other payment instrument details;
            </li>
            <li>physical, physiological and mental health condition;</li>
            <li>sexual orientation;</li>
            <li>medical records and history;</li>
            <li>biometric information;</li>
            <li>
              information received by body corporate under lawful contract or
              otherwise;
            </li>
            <li>
              visitor details as provided at the time of registration or
              thereafter; and
            </li>
            <li>
              call data records.
              <span class="float-left full-width">
                The Author will be free to use, collect and disclose information
                that is freely available in the public domain without your
                consent.
              </span>
            </li>
          </ol>
          <div class="vertical-space-5"></div>
          <h4>
            The Author has provided this Privacy Policy to familiarise You with:
          </h4>
          <ul>
            <li>
              The type of data or information that You share with or provide to
              the Author and that the Author collects from You;
            </li>
            <li>
              The purpose for collection of such data or information from You;
            </li>
            <li>
              Author’s policy on sharing or transferring Your data or information
              with third parties. This Privacy Policy may be amended / updated
              from time to time. Upon amending / updating the Privacy Policy, we
              will accordingly amend the date above. We suggest that you regularly
              check this Privacy Policy to apprise yourself of any updates. Your
              continued use of Website or provision of data or information
              thereafter will imply Your unconditional acceptance of such updates
              to this Privacy Policy.
            </li>
            <li>
              Information collected and storage of such Information: The
              "Information" (which shall also include data) provided by You to the
              Author or collected from You by Author may consist of "Personal
              Information" and "Non-Personal Information".
            </li>
            <li>
              Personal Information: Personal Information is Information collected
              that can be used to uniquely identify or contact You. Personal
              Information for the purposes of this Privacy Policy shall include,
              but not be limited to:
              <ul>
                <li>Your user name along with Your password,</li>
                <li>
                  Your name,
                </li>
                <li>Your address,</li>
                <li>Your telephone number,</li>
                <li>Your e-mail address or other contact information,</li>
                <li>Your date of birth,</li>
                <li>Your gender,</li>
                <li>
                  Information regarding your transactions, (including sales or
                  purchase history),
                </li>
                <li>
                  Your financial information such as bank account information or
                  credit card or debit card or other payment instrument details
                  including OTP,
                </li>
                <li>
                  Internet Protocol address,
                </li>
                <li>
                  Any other items of ‘sensitive personal data or information' as
                  such term is defined under the Information Technology
                  (Reasonable Security Practices And Procedures And Sensitive
                  Personal Data Of Information) Rules, 2011 enacted under the
                  Information Technology Act, 2000;
                </li>
                <li>
                  Identification code of your communication device which You use
                  to access the App or otherwise deal with the Author,
                </li>
                <li>
                  Any other Information that You provide during Your registration
                  process, if any, on the Website. Such Personal Information may
                  be collected in various ways including during the course of You:
                  <ul>
                    <li>registering as a user on the Website,</li>
                    <li>registering as a seller on the Website,</li>
                    <li>
                      availing certain services offered on the Website. Such
                      instances include but are not limited to making an offer for
                      sale, online purchase, participating in any online survey or
                      contest, communicating with Author’s customer service by
                      phone, email or otherwise or posting user reviews on the
                      items available on the Website, or
                    </li>
                    <li>
                      otherwise doing business on the Website or otherwise dealing
                      with Author. We may receive Personal information about you
                      from third parties, such as social media services,
                      commercially available sources and business partners. If you
                      access Website through a social media service or connect a
                      service on Website to a social media service, the
                      information we collect may include your user name associated
                      with that social media service, any information or content
                      the social media service has the right to share with us,
                      such as your profile picture, email address or friends list,
                      and any information you have made public in connection with
                      that social media service. When you access the Website or
                      otherwise deal with any Author entity through social media
                      services or when you connect any Website to social media
                      services, you are authorizing Author to collect, store, and
                      use and retain such information and content in accordance
                      with this Privacy Policy.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Non-Personal Information: Author may also collect information other
              than Personal Information from You through the Website when You
              visit and / or use the Website. Such information may be stored in
              server logs. This Non-Personal Information would not assist us to
              identify You personally. This Non-Personal Information may include:
              <ul>
                <li>Your geographic location,</li>
                <li>
                  details of Your telecom service provider or internet service
                  provider,
                </li>
                <li>
                  the type of browser (Internet Explorer, Firefox, Opera, Google
                  Chrome etc.),
                </li>
                <li>
                  the operating system of Your system, device and the Website You
                  last visited before visiting the Website,
                </li>
                <li>
                  The duration of Your stay on the Website is also stored in the
                  session along with the date and time of Your access,
                  Non-Personal Information is collected through various ways such
                  through the use of cookies. Author may store temporary or
                  permanent ‘cookies' on Your computer. You can erase or choose to
                  block these cookies from Your computer. You can configure Your
                  computer's browser to alert You when we attempt to send You a
                  cookie with an option to accept or refuse the cookie. If You
                  have turned cookies off, You may be prevented from using certain
                  features of the Website.
                </li>
                <li>
                  Ads: Author may use third-party service providers to serve ads
                  on Author’s behalf across the internet and sometimes on the
                  Website. They may collect Non-Personal Information about Your
                  visits to the Website, and Your interaction with our products
                  and services on the Website. Please do note that Personal
                  Information and Non-Personal Information may be treated
                  differently as per this Privacy Policy.
                </li>
              </ul>
            </li>
          </ul>
          <h4>You hereby represent to Author that:</h4>
          <ul>
            <li>
              the Information you provide to Author from time to time is and shall
              be authentic, correct, current and updated and You have all the
              rights, permissions and consents as may be required to provide such
              Information to Author.
            </li>
            <li>
              Your providing the Information to Author and Author’s consequent
              storage, collection, usage, transfer, access or processing of the
              same shall not be in violation of any third-party agreement, laws,
              charter documents, judgments, orders and decrees.
            </li>
            <li>
              Author and its officers, directors, contractors or agents shall not
              be responsible for the authenticity of the Information that You or
              any other user provide to them. You shall indemnify and hold
              harmless the Author and its officers, directors, contracts or agents
              and any third party relying on the Information provided by You in
              the event You are in breach of this Privacy Policy including this
              provision and the immediately preceding provision above.
            </li>
            <li>
              Your Information will primarily be stored in electronic form however
              certain data can also be stored in physical form. We may store,
              collect, process and use your data in countries other than Republic
              of India but under compliance with applicable laws. We may enter
              into agreements with third parties (in or outside of India) to store
              or process your information or data. These third parties may have
              their own security standards to safeguard your information or data
              and we will on commercial reasonable basis require from such third
              parties to adopt reasonable security standards to safeguard your
              information / data.
            </li>
            <li>
              Purpose for collecting, using, storing and processing Your
              Information: Author collects, uses, stores and processes Your
              Information for any purpose as may be permissible under applicable
              laws (including where the applicable law provides for such
              collection, usage, storage or processes in accordance with the
              consent of the user) connected with a function or activity of the
              Author and shall include the following:
              <ul>
                <li>
                  to facilitate Your use of the App or other services;
                </li>
                <li>
                  to respond to Your inquiries or fulfil Your requests for
                  information about the various products and services offered on
                  the Website;
                </li>
                <li>
                  to provide You with information about products and services
                  available on the Website and to send You information, materials,
                  and offers;
                </li>
                <li>
                  to send You important information regarding the Website, changes
                  in terms and conditions, user agreements, and policies and/or
                  other administrative information;
                </li>
                <li>
                  to send You surveys and marketing communications that Author
                  believes may be of interest to You;
                </li>
                <li>
                  to personalize Your experience on the Website by presenting
                  advertisements, products and offers tailored to Your
                  preferences;
                </li>
                <li>
                  to help You address Your problems incurred on the Website
                  including addressing any technical problems;
                </li>
                <li>
                  if You purchase any product or avail of any service from the
                  Website, to complete and fulfil Your purchase, for example, to
                  have Your payments processed, communicate with You regarding
                  Your purchase and provide You with related customer service;
                </li>
                <li>for proper administering of the Website;</li>
                <li>
                  to conduct internal reviews and data analysis for the Website
                  (e.g., to determine the number of visitors to specific pages
                  within the Website);
                </li>
                <li>
                  to improve the services, content and advertising on the Website;
                </li>
                <li>
                  to provide value added services such as Single Sign On. Single
                  Sign On shall mean a session/user authentication process that
                  permits a user to enter his/her name or mobile number or e-mail
                  address or any combination thereof and password in order to
                  access multiple websites and applications;
                </li>
                <li>
                  to facilitate seamless purchase experience while making a
                  transaction by pre-populating the OTP received through SMS by
                  you
                </li>
                <li>
                  to facilitate various programmes and initiatives launched by
                  Author or third party service providers and business associates
                </li>
                <li>
                  to analyse how our services are used, to measure the
                  effectiveness of advertisements, to facilitating payments
                </li>
                <li>to conducting academic research and surveys</li>
                <li>to protect the integrity of the Website;</li>
                <li>
                  to respond to legal, judicial, quasi-judicial process and
                  provide information to law enforcement agencies or in connection
                  with an investigation on matters related to public safety, as
                  permitted by law;
                </li>
                <li>
                  to conduct analytical studies on various aspects including user
                  behaviour, user preferences etc.;
                </li>
                <li>
                  to permit third parties who may need to contact users who have
                  bought products from the Website to facilitate installation,
                  service and any other product related support;
                </li>
                <li>to implement information security practices;</li>
                <li>
                  to determine any security breaches, computer contaminant or
                  computer virus;
                </li>
                <li>
                  to investigate, prevent, or take action regarding illegal
                  activities and suspected fraud,
                </li>
                <li>
                  to undertake forensics of the concerned computer resource as a
                  part of investigation or internal audit;
                </li>
                <li>
                  to trace computer resources or any person who may have
                  contravened, or is suspected of having or being likely to
                  contravene, any provision of law including the Information
                  Technology Act, 2000 that is likely to have an adverse impact on
                  the services provided on any Website or by Author;
                </li>
                <li>
                  to enable a potential buyer or investor to evaluate the business
                  of Author ("Purposes") You hereby agree and acknowledge that the
                  Information so collected is for lawful purpose connected with a
                  function or activity of Author or any person on their respective
                  behalf, and the collection of Information is necessary for the
                  Purposes.
                </li>
              </ul>
            </li>
          </ul>
          <h4>Sharing and disclosure of Your Information</h4>
          <p>
            You hereby unconditionally agree and permit that Author may transfer,
            share, disclose or part with all or any of Your Information, within
            and outside of the Republic of India to various entities and to third
            party service providers / partners / banks and financial institutions
            for one or more of the Purposes or as may be required by applicable
            law. In such case we will contractually oblige the receiving parties
            of the Information to ensure the same level of data protection that is
            adhered to by Author under applicable law.
          </p>
          <p>
            You acknowledge and agree that, to the extent permissible under
            applicable laws, it is adequate that when Author transfers Your
            Information to any other entity within or outside Your country of
            residence, Author will place contractual obligations on the transferee
            which will oblige the transferee to adhere to the provisions of this
            Privacy Policy.
          </p>
          <p>
            You unconditionally agree and permit Author to access and read your
            SMS to autofill or prepopulate ‘OTP’ while making a transaction and to
            validate your mobile number.
          </p>
          <p>
            Author may share statistical data and other details (other than Your
            Personal Information) without your express or implied consent to
            facilitates various programmes or initiatives launched by Author, its
            affiliates, agents, third party service providers, partners or banks &
            financial institutions, from time to time. We may
            transfer/disclose/share Information (other than Your Personal
            Information) to those parties who support our business, such as
            providing technical infrastructure services, analysing how our
            services are used, measuring the effectiveness of advertisements,
            providing customer / buyer services, facilitating payments, or
            conducting academic research and surveys. These affiliates and
            third-party service providers shall adhere to confidentiality
            obligations consistent with this Privacy Policy. We may share Your
            relevant Personal Information as necessary for the third parties to
            provide appropriate services, including your name, residence and email
            address. The processing of payments or authorization is solely in
            accordance with these third parties’ policies, terms and conditions
            and we are not in any manner responsible or liable to You or any third
            party for any delay or failure at their end in processing the
            payments.
          </p>
          <p>
            Author may also share Personal Information if Author believe it is
            necessary in order to investigate, prevent, or take action regarding
            illegal activities, suspected fraud, situations involving potential
            threats to the physical safety of any person, violations of various
            terms and conditions or our policies.
          </p>
          <p>
            We reserve the right to disclose your information when required to do
            so by law or regulation, or under any legal obligation or order under
            law or in response to a request from a law enforcement or governmental
            agency or judicial, quasi-judicial or any other statutory or
            constitutional authority or to establish or exercise our legal rights
            or defend against legal claims.
          </p>
          <p>
            You further agree that such disclosure, sharing and transfer of Your
            Personal Information and Non-Personal Information shall not cause any
            wrongful loss to You or to any third party, or any wrongful gain to us
            or to any third party.
          </p>
          <h4>Links to third party websites</h4>
          <p>
            Links to third-party advertisements, third-party websites or any
            third-party electronic communication service may be provided on the
            Website which are operated by third parties and are not controlled by,
            or affiliated to, or associated with, Author unless expressly
            specified otherwise.
          </p>
          <p>
            Author is not responsible for any form of transmission, whatsoever,
            received by You from any third-party website. Accordingly, Author does
            not make any representations concerning the privacy practices or
            policies of such third parties or terms of use of such third party
            websites, nor does Author control or guarantee the accuracy,
            integrity, or quality of the information, data, text, software, music,
            sound, photographs, graphics, videos, messages or other materials
            available on such third party websites. The inclusion or exclusion
            does not imply any endorsement by Author of the third-party websites,
            the website's provider, or the information on the website. The
            information provided by You to such third-party websites shall be
            governed in accordance with the privacy policies of such third-party
            websites and it is recommended that You review the privacy policy of
            such third -party websites prior to using such websites.
          </p>
          <h4>Security & Retention</h4>
          <p>
            The security of your Personal Information is important to us. Author
            strives to ensure the security of Your Personal Information and to
            protect Your Personal Information against unauthorized access or
            unauthorized alteration, disclosure or destruction. For this purpose,
            Author adopts internal reviews of the data collection, storage and
            processing practices and security measures, including appropriate
            encryption and physical security measures to guard against
            unauthorized access to systems where Author stores Your Personal
            Information. Author shall adopt reasonable security practices and
            procedures as mandated under applicable laws for the protection of
            Your Information. Provided that Your right to claim damages shall be
            limited to the right to claim only statutory damages under Information
            Technology Act, 2000 and You hereby waive and release Author from any
            claim of damages under contract or under tort.
          </p>
          <p>
            If you choose a payment gateway to complete any transaction on Website
            then Your credit card data may be stored in compliance with industry
            standards/ recommended data security standard for security of
            financial information such as the Payment Card Industry Data Security
            Standard (PCI-DSS).
          </p>
          <p>
            Author may share your Information with third parties under a
            confidentiality agreement which inter alia provides for that such
            third parties not disclosing the Information further unless such
            disclosure is for the Purpose. However, Author is not responsible for
            any breach of security or for any actions of any third parties that
            receive Your Personal Information. Author is not liable for any loss
            or injury caused to You as a result of You providing Your Personal
            Information to third party (including any third party websites, even
            if links to such third party websites are provided on the Website).
          </p>
          <p>
            Notwithstanding anything contained in this Policy or elsewhere, Author
            shall not be held responsible for any loss, damage or misuse of Your
            Personal Information, if such loss, damage or misuse is attributable
            to a Force Majeure Event (as defined below).
          </p>
          <p>
            A Force Majeure Event shall mean any event that is beyond the
            reasonable control of Author and shall include, without limitation,
            sabotage, fire, flood, explosion, acts of God, civil commotion,
            strikes or industrial action of any kind, riots, insurrection, war,
            acts of government, computer hacking, unauthorised access to computer,
            computer system or computer network,, computer crashes, breach of
            security and encryption (provided beyond reasonable control of
            Author), power or electricity failure or unavailability of adequate
            power or electricity.
          </p>
          <p>
            While We will endeavor to take all reasonable and appropriate steps to
            keep secure any Personal Information which We hold about You and
            prevent unauthorized access, You acknowledge that the internet or
            computer networks are not fully secure and that We cannot provide any
            absolute assurance regarding the security of Your Personal
            Information.
          </p>
          <p>
            You agree that all Personal Information shall be retained till such
            time required for the Purpose or required under applicable law,
            whichever is later. Non-Personal Information will be retained
            indefinitely.
          </p>
          <h4>User discretion and opt out</h4>
          <p>
            You agree and acknowledge that You are providing your Information out
            of your free will. You have an option not to provide or permit Author
            to collect Your Personal Information or later on withdraw Your consent
            with respect to such Personal Information so provided herein by
            sending an email to the grievance officer or such other electronic
            address of the respective Author entity as may be notified to You. In
            such case, You should neither visit any Website nor use any services
            provided by Author nor shall contact any of Author entities. Further,
            Author may not deliver products to You, upon Your order, or Author may
            deny you access from using certain services offered on the Website.
          </p>
          <p>
            You can add or update Your Personal Information on regular basis.
            Kindly note that Author would retain Your previous Personal
            Information in its records.
          </p>
          <h4>Grievance Officer</h4>
          <p>
            If you find any discrepancies or have any grievances in relation to
            the collection, storage, use, disclosure and transfer of Your Personal
            Information under this Privacy Policy or any terms of Author’s Terms
            of Use, Term of Sale and other terms and conditions or polices of
            Author, please contact the following: Mr. ________ the designated
            grievance officer under Information Technology Act, 2000 E-mail:
            ________________.
          </p>
          <p>
            The details of the grievance officer may be changed by us from time to
            time by updating this Privacy Policy.
          </p>
          <h4>Further Acknowledgements</h4>
          <p>You hereby acknowledge and agree that this Privacy Policy:</p>
          <ul>
            <li>
              is clear and easily accessible and provide statements of Author
              policies and practices with respective to the Information;
            </li>
            <li>
              provides for the various types of personal or sensitive personal
              data of information to be collected;
            </li>
            <li>
              provides for the purposes of collection and usage of the
              Information;
            </li>
            <li>provides for disclosure of Information; and</li>
            <li>provides for reasonable security practices and procedures.</li>
          </ul>
          <div class="vertical-space-60"></div>
        </div>
      </div>
      
      <Footer></Footer>

      <Modal className="our-client-video-modal" show={show} onHide={handleClose} closeButton>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <iframe width="420" height="345" src="https://www.youtube.com/embed/i-33DB3lsz8"></iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
