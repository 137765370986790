import React, { useState } from "react";
import about_us from "./images/about-us.jpg";
import Footer from "./footer";
import HeaderNavbar from "./header-navbar";
import "react-multi-carousel/lib/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { Button, Row, Col, Container, Grid } from "react-bootstrap";
import img16 from "./image/founder1.jpg";
import img17 from "./image/founder2.jpg";

function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const about_data = [
    {
      id: 1,
      imageUrl: img16,
      heading: "RAJESH SUBRAMANIAN",
      subheading0: "CEO",
      subheading1: "EX-AMAZON",
      description0: "15+ years in Software Development ",
      description1: "B.E Computer Science",
      description2: "EX-Amazon (10 years), EX-SAP",
      description3: "Leads product and software development",
    },
    {
      id: 2,
      imageUrl: img17,
      heading: "SANJAY DEV TJ ",
      subheading0: "CMO",
      subheading1: "IIM-K",
      description0: "15+ years in Sales and Operations",
      description1: "B.E Electrical and Electronics, MBA Marketing",
      description2: "EX-Honeywell, EX-Bose, EX-Saint Gobain",
      description3: "Leads Sales, Marketing and Operations",
    },
  ];
  return (
    <div className="body full-width float-left">
      <div className="header other-page-header" id="home">
        <HeaderNavbar />
      </div>
      <div className="terms_conditions about-us">
        <div className="container">
          <div className="flex-container">
            {/* <div className="imageAbout" /> */}
            <div className=" aboutUSContent">
              <h4
                className="headAbout"
                style={{ color: "#EF4667", marginBottom: 40 }}
              >
                About Us
              </h4>
              <p className="parag">
                {/* Ghoshak is a SaaS-based mobile-first CRM solution built for
                small and medium businesses to digitize their business
                operations and customer management. Customers are critical to
                any business. In today's digital era, customers have moved
                online and businesses are still offline. Ghoshak has a strong
                presence in southern India with 50,000 trusted businesses. We
                are headquartered in the SaaS capital of India, Chennai. Ghoshak
                is trusted as a one-stop retail tech solution for businesses to
                go digital without any investment into hardware. Ghoshak has
                partnered with retail chains to effectively digitize their
                franchise/ retail partners to help retailers manage all their
                franchises/retail chains from one dashboard. */}
                Ghoshak, a product of Zoryboard Software Solution Private
                Limited. <br />
                Ghoshak means announcer, and we want you to announce your
                products and services to your customers effectively and
                affordably. As iconic as our name is, we believe in doing iconic
                things to support small and medium-size businesses all over. YOU
                ARE YOUR OWN BOSS! If you own any kind of small business, be it
                a salon, a boutique, a gift shop, an electronics shop, or
                anything and everything under the umbrella of products and
                services, we will help you grow. Our readily available mobile
                app will support all your business needs, from taking online
                orders, online payments, creating a website, generating detailed
                customer reports and much more.
              </p>
            </div>
          </div>
          {/* <h2
            className=" text-center headAbout"
            style={{ marginTop: 60, color: "#EF4667", marginBottom: 20 }}
          >
            FOUNDERS
          </h2> */}
          {/* <div className="containerAbout">
          <Container>
            <Row >
              {about_data.map((data) => (
                <Col md={6} sm={12}>
                  <div className="aboutUsCards" key={data.id}>
                    <Row className="aboutUsCards row" >
                      <Col md={3} sm={6}>
                        <img src={data.imageUrl} width="200px" height="250px" />
                      </Col>
                      <Col md={9} sm={6}>
                        <div className="aboutContent">
                        <div
                            className="aboutUsContentHead"
                            style={{ color: "#000",marginTop:-20 }}
                          >
                            <p>{data.heading}</p>
                            <p className="aboutUsSub">{data.subheading0}</p>
                            <p className="aboutUsSub">{data.subheading1}</p>

                          </div>
                          <div
                            className="aboutUsContentPara"
                            style={{ color: "#3F3F3F" }}
                          >
                            <p>{data.description0}</p>
                            <p className="aboutUsDesp" >{data.description1}</p>
                            <p className="aboutUsDesp" >{data.description2}</p>
                            <p className="aboutUsDesp" >{data.description3}</p>

                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
          </div> */}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
