import React from "react";

function ZoomForGhoshakUnInstall() {
  return (
    <div className="body full-width float-left">
      <p style={{ textAlign: "center", fontSize: "45px" }}>
        Zoom for Ghoshak Uninstalled
      </p>
    </div>
  );
}

export default ZoomForGhoshakUnInstall;
