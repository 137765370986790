import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./images/Logo_Orginal.png";
import Newsletter_July from "./pdf/Newsletter-July.pdf";
import Newsletter_Sep from "./pdf/Newsletter-Sep.pdf";
import img4 from "./image/fb.png";
import img5 from "./image/insta.png";
import img6 from "./image/youtube.png";
import img7 from "./image/LinkedIN.png";
const Footer = () => {
  const [pdfshow1, setShow1] = useState(false);
  const pdfhandleClose1 = () => setShow1(false);
  const pdfhandleShow1 = () => setShow1(true);
  const [pdfshow2, setShow2] = useState(false);
  const pdfhandleClose2 = () => setShow2(false);
  const pdfhandleShow2 = () => setShow2(true);
  return (
    <>
      <div className="footer" style={{ backgroundColor: "#e69158" }}>
        <div className="footerContainer">
          {/* <div className="vertical-space-60"></div> */}
          <div className="row g-0">
            <div className="col-lg-12 ">
              <div className="row g-0">
                <div className="col-lg-4 col-sm-6">
                  <h5>More Info</h5>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/aboutUs" href="#home">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/Career" href="#home">
                        Careers
                      </Link>
                    </li>
                    <li>
                      <Link to="/merchantTerms" href="#home">
                        Terms and Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to="/PrivacyPolicy" href="#home">
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-5 col-sm-6">
                  <h5>Follow Us</h5>
                  <div className="social">
                    <div className="row justify-content-center no-gutters">
                      <div className="col-3">
                        <div
                          className="imageSpace"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={img4}
                            height={40}
                            width={40}
                            onClick={() =>
                              window.open(
                                "https://m.facebook.com/ghoshakindia",
                                "_blank"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div
                          className="imageSpace"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={img5}
                            height={40}
                            width={40}
                            onClick={() =>
                              window.open(
                                "https://www.instagram.com/ghoshakindia/?hl=en",
                                "_blank"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-3 ">
                        <div
                          className="imageSpace"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={img6}
                            height={40}
                            width={40}
                            onClick={() =>
                              window.open(
                                "https://www.youtube.com/channel/UCaqtC5cZdeTXWYtLhwn2-RQ",
                                "_blank"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-3 ">
                        <div
                          className="imageSpace"
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={img7}
                            height={40}
                            width={40}
                            onClick={() =>
                              window.open(
                                "https://in.linkedin.com/company/ghoshak",
                                "_blank"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 ">
                  <h5>Contact Us</h5>
                  <div className="detail-box">
                    <ul className="list-unstyled">
                      <h6>support@ghoshak.com</h6>
                    </ul>
                    <ul className="list-unstyled">
                      <h6>+91 7401066066</h6>
                    </ul>
                    <ul className="list-unstyled">
                      <h6>
                        Eldorado Building, 7A, Nungambakkam High Rd, Thousand
                        Lights West, Thousand Lights, Chennai, Tamil Nadu
                        600034.
                      </h6>
                    </ul>
                  </div>
                </div>
                <Modal
                  className="pdf-modal"
                  show={pdfshow1}
                  onHide={pdfhandleClose1}
                  closebutton
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <embed
                      src={Newsletter_July}
                      type="application/pdf"
                      frameBorder="0"
                      scrolling="auto"
                      height="100%"
                      width="100%"
                    ></embed>
                  </Modal.Body>
                </Modal>

                <Modal
                  className="pdf-modal"
                  show={pdfshow2}
                  onHide={pdfhandleClose2}
                  closebutton
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <embed
                      src={Newsletter_Sep}
                      type="application/pdf"
                      frameBorder="0"
                      scrolling="auto"
                      height="100%"
                      width="100%"
                    ></embed>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
          <div className="vertical-space-10"></div>
          <div>
            <div className="footerimg">
              <Link to="/" href="#home">
                <img src={logo} className="logo" alt="logo" />
              </Link>
            </div>
            <p className="copyright-text">
              Product by Zoryboard Software Solutions Private Limited
            </p>
          </div>
        </div>
        {/* <div
          style={{ width: "100%", height: 35, backgroundColor: "#ef4667" }}
        ></div> */}
      </div>
    </>
  );
};

export default Footer;
