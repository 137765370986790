import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Navbar,
  Nav,
  Button,
  Container,
  FormControl,
  Form,
  NavDropdown,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./images/logoimg.png";
const Footer = (props) => {
  useEffect(() => {
    console.log("hello navbar", props);
  }, []);
  return (
    <>
      <Navbar bg="light" expand="lg" style={{ backgroundColor: "#fff" }}>
        <div className="container-sm">
          <Navbar.Brand>
            {" "}
            <Link to="/" href="#home"></Link>
            <img
              src={logo}
              className="App-logo full-height"
              alt="logo"
              style={{ width: "150px", height: "50px", marginRight: "20px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{
              backgroundColor: "#fff",
              fontSize: 15,
              marginBottom: 10,
              marginRight: 10,
            }}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {/* {props.navigation != "homepage" ? (
                <>
                  <Nav>
                    <Link
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: 5,
                        boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                        fontSize: 20,
                      }}
                      to="/"
                    >
                      Home
                    </Link>
                  </Nav>
                  <Nav>
                    <Link
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: 5,
                        boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                        fontSize: 20,
                      }}
                      // to="/"
                      to="/#features"
                      // href="#features"
                    >
                      Features
                    </Link>
                  </Nav>
                  <Nav>
                    <Link
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: 5,
                        boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                        fontSize: 20,
                      }}
                      to="/#testimonials"
                      href="#testimonials"
                    >
                      Testimonials
                    </Link>
                  </Nav>
                  <Nav>
                    <Link
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: 5,
                        boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                        fontSize: 20,
                      }}
                      to="/"
                      href="#designs"
                    >
                      Designs
                    </Link>
                  </Nav>
                </>
              ) : ( */}
              <>
                <Nav>
                  <a
                    style={{
                      backgroundColor: "transparent",
                      borderRadius: 5,
                      boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                      fontSize: 20,
                      paddingRight: 40,
                      color: "#000",
                    }}
                    onClick={() => props.history.push("/#home")}
                    href="#home"
                  >
                    Home
                  </a>
                </Nav>
                <Nav>
                  <a
                    style={{
                      backgroundColor: "transparent",
                      borderRadius: 5,
                      boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                      fontSize: 20,
                      paddingRight: 40,
                      color: "#000",
                    }}
                    // to="/"
                    onClick={() => props.history.push("/#features")}
                    href="#features"
                  >
                    Features
                  </a>
                </Nav>
                <Nav>
                  <a
                    style={{
                      backgroundColor: "transparent",
                      borderRadius: 5,
                      boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                      fontSize: 20,
                      paddingRight: 50,
                      color: "#000",
                    }}
                    onClick={() => props.history.push("/#testimonials")}
                    href="#testimonials"
                  >
                    Testimonials
                  </a>
                </Nav>
                <Nav>
                  <a
                    style={{
                      backgroundColor: "transparent",
                      borderRadius: 5,
                      boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                      fontSize: 20,
                      color: "#000",
                    }}
                    href="#designs"
                    onClick={() => props.history.push("/#designs")}
                  >
                    Designs
                  </a>
                </Nav>
              </>
              {/* )} */}

              {/* <Nav>
                <a
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: 5,
                    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                    fontSize: 20,
                    paddingRight: 40,
                    color: "#000",
                  }}
                  href="#features"
                >
                  Features
                </a>
              </Nav>
              <Nav>
                <a
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: 5,
                    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                    fontSize: 20,
                    paddingRight: 50,
                    color: "#000",
                  }}
                  href="#testimonials"
                >
                  Testimonials
                </a>
              </Nav>
              <Nav>
                <a
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: 5,
                    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                    fontSize: 20,
                    color: "#000",
                  }}
                  href="#designs"
                >
                  Designs
                </a>
              </Nav> */}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      {/* <Navbar bg="light" expand="lg" style={{ backgroundColor: "#fff" }}>
        <Container fluid >
          <Navbar.Brand>
            <Link to="/" href="#home">
              <img
                src={logo}
                className="App-logo full-height"
                alt="logo"
                style={{ width: "150px", height: "50px",marginRight:"20px" }}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{ backgroundColor: "#fff", fontSize: 15, marginBottom: 10 ,marginRight:10}}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="mr-auto my-4 my-lg-0"
              style={{ maxHeight: "150px" }}
            >
              <Nav>
                <a
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: 5,
                    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                    fontSize: 20,
                    paddingRight: 40,
                    color: "#000",
                  }}
                  href="#home"
                >
                  Home
                </a>
              </Nav>
              <Nav>
                <a
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: 5,
                    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                    fontSize: 20,
                    paddingRight: 40,
                    color: "#000",
                  }}
                  href="#features"
                >
                  Features
                </a>
              </Nav>
              <Nav>
                <a
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: 5,
                    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                    fontSize: 20,
                    paddingRight: 50,
                    color: "#000",
                  }}
                  href="#testimonials"
                >
                  Testimonials
                </a>
              </Nav>
              <Nav>
                <a
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: 5,
                    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0)",
                    fontSize: 20,
                    color: "#000",
                  }}
                  href="#designs"
                >
                  Designs
                </a>
              </Nav>
            </Nav>
            <Link to="/designs" href="#home">
              <button type="submit" className="loginbtn" style={{ backgroundColor: "#f14959" }}>
                LOGIN
              </button>
            </Link>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
    </>
  );
};

export default withRouter(Footer);
