import React, { useState } from 'react';
import Footer from "./footer"
import HeaderNavbar from "./header-navbar"
import "react-multi-carousel/lib/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="body full-width float-left">
      <div className="header other-page-header" id="home">
        <HeaderNavbar />
      </div>
      <div className="terms_conditions">
        <div className="container">
          <div className="vertical-space-60"></div>
          <h4 className="df-hr text-center df-color">CAREERS</h4>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8"><p className="text-center">Wish to create a difference in the life of small retailers? then join us!! Be a part of a fun, enthusiastic team who are self motivated and highly passionate, who also push each other to their individual best. Drop your resumes at <a className="df-color">resume@ghoshak.com</a> and be a part of our journey. </p></div>
            <div className="col-md-2"></div>
          </div>
          <div className="vertical-space-30"></div>
          <div className="job-opening">
            <h4>Product Manager</h4>
            <p>Ghoshak is looking for Product manager, who will be responsible for guiding the success of a product and leading the cross-functional team that is responsible for improving it. It is an important organizational role that sets the strategy, roadmap, and feature definition for a product or product line. You will be working closely with the founding team.</p>
            <ul className="list-unstyled">
              <li><strong>Location:</strong> Chennai</li>
              <li><strong>Experience:</strong> 1-5 years of experience.</li>
              <li><strong>Salary:</strong> 25k-45k per month.</li>
            </ul>
            <p>Send resume to <a className="df-color">resume@ghoshak.com</a></p>
            <h5>What you will do?</h5>
            <ul className="list-unstyled">
              <li>- Analyze market and competitive conditions, laying out a product vision that is differentiated and delivers unique value based on customer demands.</li>
              <li>- Work with cross-functional leadership — most notably between engineering, marketing, sales, and support teams.</li>
              <li>- You will be responsible for defining the why, when and what of the product that the engineering team builds</li>
              <li>- You will own the roadmap and must prioritize building what matters most to achieve the strategic goals and initiatives behind the product.</li>
              <li>- You will be responsible for defining the requirements for each feature and the desired user experience. You work closely with engineering and UX designer on the technical specifications and ensure that teams have all of the information they need to deliver a complete product to market.</li>
            </ul>
            <h5>Experience and skill set</h5>
            <ul className="list-unstyled">
              <li>- 1-5 years of software product management experience.</li>
              <li>- Experience in designing User experience with wireframes.</li>
              <li>- Passion to solve problems with use of technology.</li>
              <li>- Strong analytical skills.</li>
              <li>- MBA or B.E with 2-3 years of experience in software industry is preferred.</li>
              <li>- Experience as a developer is an advantage but not preferred.</li>
            </ul>
            <p><strong>Note :</strong> We are open to application from women who would like to resume work after a break in career. We offer full time role on successful completion of internship.</p>
          </div>
          <div className="vertical-space-30"></div>
          <div className="job-opening">
            <h4>Marketing Interns</h4>
            <p>Ghoshak is looking for Marketing interns. This is an exciting opportunity for someone eager to learn about marketing functions, responsibilities and launch new products and campaign in a startup. The ideal candidate is data-driven, results-oriented and both a self-starter and team player who possesses a passion for driving growth through brand, marketing and product. You will get an opportunity to be a full time with company based on the performance.</p>
            <h5>Qualification. </h5>
            <ul className="list-unstyled">
              <li>Must be a Graduate or Post Graduate in Marketing.</li>
              <li>Excellent written and verbal communication skills</li>
            </ul>
            <ul className="list-unstyled">
              <li>Location: Chennai</li>
              <li>Duration: 3 months</li>
              <li>Stipend: 8,000/- per month.</li>
            </ul>
            <p>Send resume to <a className="df-color">resume@ghoshak.com</a></p>
          </div>
          <div className="vertical-space-30"></div>
          <div className="job-opening">
            <h4>Digital Media Intern</h4>
            <p>Ghoshak is looking for an ambitious digital media intern to drive and execute a robust growth strategy on Facebook, Twitter and Instagram in collaboration with our content team. The successful candidate is obsessed with social media and how content is consumed on emerging platforms. Creativity, analytical thinking and an entrepreneurial spirit are key to succeeding in this role. You will get an opportunity to be a full time with company based on the performance.</p>
            <h5>Expectations</h5>
            <ul className="list-unstyled">
              <li>Strong knowledge of Facebook, Twitter, Instagram and emerging platforms</li>
              <li>Excellent written and verbal communication skills</li>
              <li>Compelling, conversational voice that facilitates audience engagement and growth</li>
              <li>Understanding of virality and ability to generate story ideas with strong social potential</li>
              <li>Proficient with basic photo editing technology</li>
              <li>Strong organizational skills</li>
              <li>Ability to work both autonomously and collaboratively</li>
            </ul>
            <ul className="list-unstyled">
              <li><strong>Location:</strong> Chennai</li>
              <li><strong>Duration:</strong> 3 months</li>
              <li><strong>Stipend:</strong> 8,000/- per month.</li>
            </ul>
            <p>Send resume to <a className="df-color">resume@ghoshak.com</a></p>
          </div><div className="vertical-space-30"></div>
          <div className="job-opening">
            <h4>Software Developer</h4>
            <p>Ghoshak is hiring full stack developer. We are building mobile application for advertising, marketing and sales verticals for which we are looking for passionate members to join our technical team.</p>
            <h5>Expectations</h5>
            <ul className="list-unstyled">
              <li>Developing front end & back end development.</li>
              <li>Strong in OOPS.</li>
              <li>Understanding and implementation of security and data protection.</li>
              <li>Design overall architecture of app.</li>
              <li>Experience with React Native or AWS is preferred.</li>
            </ul>
            <ul className="list-unstyled">
              <li>Key Skills (Expertise in any 3 technical skills)</li>
              <li>React Native, React, Node.js Java, php, .net, Java Script, Android and iOS mobile development.</li>
            </ul>
            <ul className="list-unstyled">
              <li>Required Experience and Qualifications</li>
              <li>1-2 years</li>
            </ul>
            <ul className="list-unstyled">
              <li><strong>Job Type:</strong> Full-time</li>
              <li><strong>Location:</strong> Chennai</li>
              <li><strong>Salary:</strong> 15K to 30K /month</li>
            </ul>
            <p>Send resume to <a className="df-color">resume@ghoshak.com</a></p>
          </div>
          <div className="vertical-space-60"></div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
